import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './search-result.module.scss';
import { Link } from 'gatsby';
import CompareStateToggle from '../compare-state-toggle/compare-state-toggle';
import { SearchKitContext } from '../searchkit-context-provider';
import { useInterventions } from '../../global/hooks/useInterventions';
import { getSubtitle } from '../../utils';
import { groupBy } from 'lodash';

const SearchResult = ({ hit }) => {
  const {
    title,
    key_program_documents,
    type,
    uuid,
    field_intervention_type,
    key_terms,
    country_region_name,
    url,
    _highlightResult
  } = hit;

  const interventions = useInterventions();
  // Grabbing all possible intervention filter types
  const allFilters = interventions[0].allFilters;
  // Checking to see which tags our results match with, and returning the intervention name.
  const interventionTags = allFilters
    .filter((filter) =>
      field_intervention_type.includes(filter.drupal_internal__tid.toString())
    )
    .map((filter) => filter.name);

  const { selectedResourceTypes } = useContext(SearchKitContext);
  const showKeyProgamDocuments =
    key_program_documents && selectedResourceTypes.length ? true : false;

  const validDocuments = key_program_documents?.filter((document) =>
    selectedResourceTypes.includes(document.typeId)
  );
  // Grouping documents by document type.
  const groupedDocuments = groupBy(validDocuments, (item) => item.type);
  // Creating an object for each grouped document type to include type and key.
  const groupedProgramArrays = Object.keys(groupedDocuments).map((key) => {
    return { type: key, results: groupedDocuments[key] };
  });
  return (
    <article className={styles.hit}>
      <div className={styles.resultContent}>
        <p className={styles.resultEyebrow}>{interventionTags.join(', ')}</p>
        <h2 className={styles.hitTitle}>
          <Link className={styles.hitTitle} to={url[0]}>
            <span
              dangerouslySetInnerHTML={{
                __html: _highlightResult?.title[0]?.value || title
              }}
            ></span>
          </Link>
        </h2>
        {key_terms && (
          <p className={styles.hitSubtitle}>
            {/* The third arg truncates the subtitle. This is only necessary while there is testing data. */}
            {getSubtitle(key_terms, country_region_name, true)}
          </p>
        )}
        {showKeyProgamDocuments && (
          <ul className={styles.documentListing}>
            {groupedProgramArrays.map((document, delta) => (
              <li key={delta} className={styles.documentItem}>
                <p className={styles.documentType}>{document.type}</p>
                {document.results.map((result, index) => (
                  <a
                    className={styles.documentLink}
                    href={result.url}
                    key={index}
                  >
                    {result.title}
                  </a>
                ))}
              </li>
            ))}
          </ul>
        )}
      </div>
      {type[0] === 'case_studies' && (
        <CompareStateToggle name={title} articleId={uuid[0]} />
      )}
    </article>
  );
};

SearchResult.propTypes = {
  result: PropTypes.shape({
    _source: PropTypes.shape({
      title: PropTypes.array.isRequired,
      url: PropTypes.array
    })
  }),
  highlight: PropTypes.object
};

export default SearchResult;
